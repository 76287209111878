import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SafeUrlPipe } from './_pipes/safe-url.pipe';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ArkComponent } from './ark/ark.component';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';

@NgModule({
  declarations: [
    SafeUrlPipe,
    AppComponent,
    HomeComponent,
    ArkComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PageNotFoundModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
