import { Component, OnInit, AfterViewInit } from '@angular/core';
import ScrollReveal from 'scrollreveal';
declare var jQuery: any;
declare var $: any;
interface Window {
  sr: ScrollReveal;
}
declare var window: Window;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(function () {
      (function ($) {
        "use strict"; // Start of use strict

        // Smooth scrolling using jQuery easing
        $(document).on('click', 'a.js-scroll-trigger[href*="#"]:not([href="#"])', {}, function () {
          if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $('html, body').animate({
                scrollTop: (target.offset().top - 56)
              }, 1000, "easeInOutExpo");
              return false;
            }
          }
          return true;
        });

        // Closes responsive menu when a scroll trigger link is clicked
        $(document).on('click', '.js-scroll-trigger', {}, function () {
          $('.navbar-collapse').collapse('hide');
        });

        // Activate scrollspy to add active class to navbar items on scroll
        $('body').scrollspy({
          target: '#mainNav',
          offset: 57
        });

        // Collapse Navbar
        var navbarCollapse = function () {
          // console.log('Scroll..');
          var nav = $('#mainNav');
          if (!nav.length) {
            console.log('errrrrr..');
            return;
          }
          if (nav.offset().top > 100) {
            nav.addClass("navbar-shrink");
          } else {
            nav.removeClass("navbar-shrink");
          }
        };
        // Collapse now if page is not at top
        navbarCollapse();
        // Collapse the navbar when page is scrolled
        $(window).scroll(navbarCollapse);

        // Scroll reveal calls
        window.sr = ScrollReveal();
        // setTimeout(function () {

        window.sr.reveal('.sr-icon-1', {
          delay: 200,
          scale: 0
        });
        window.sr.reveal('.sr-icon-2', {
          delay: 400,
          scale: 0
        });
        window.sr.reveal('.sr-icon-3', {
          delay: 600,
          scale: 0
        });
        window.sr.reveal('.sr-icon-4', {
          delay: 800,
          scale: 0
        });
        window.sr.reveal('.sr-button', {
          delay: 200,
          distance: '15px',
          origin: 'bottom',
          scale: 0.8
        });
        window.sr.reveal('.sr-contact-1', {
          delay: 200,
          scale: 0
        });
        window.sr.reveal('.sr-contact-2', {
          delay: 400,
          scale: 0
        });
        // }, 1000);

        // Magnific popup calls
        $('.popup-gallery').magnificPopup({
          delegate: 'a',
          type: 'image',
          tLoading: 'Loading image #%curr%...',
          mainClass: 'mfp-img-mobile',
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1]
          },
          image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
          },
          callbacks: {
            beforeOpen: function () {
              this.st.mainClass = this.st.el.attr('data-effect');
              return false;
            }
          },
          midClick: true
        });
      })(jQuery); // End of use strict
    }, 1);
  }
}
