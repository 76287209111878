import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ark',
  templateUrl: './ark.component.html',
  styleUrls: ['./ark.component.scss']
})
export class ArkComponent implements OnInit {

  servers: ServerInfo[] = [];

  constructor() {
    this.servers.push(
      {name: 'Siedlisko Behemota', map: 'The Island', address: 's1.ark.biggames.pl:27015', tags: ''},
      {name: 'Przystan Behemota', map: 'Valguero', address: 's2.ark.biggames.pl:27016', tags: ''},
      {name: 'Zatoka Behemota', map: 'Ragnarok', address: 's3.ark.biggames.pl:27017', tags: ''},
      {name: 'Grota Behemota', map: 'Genesis', address: 's4.ark.biggames.pl:27018', tags: ''},
      {name: 'Uniwersum Behemota', map: 'Extinction', address: 's5.ark.biggames.pl:27019', tags: ''},
      {name: 'Dolina Behemota', map: 'Crystal Isles', address: 's6.ark.biggames.pl:27020', tags: 'EPIC CROSS'},
      {name: 'Podziemia Behemota', map: 'Aberration', address: 's7.ark.biggames.pl:27021', tags: ''},
      {name: 'Port Behemota', map: 'Ragnarok v2', address: 's8.ark.biggames.pl:27022', tags: 'EPIC CROSS'},
      {name: 'Pustkowie Behemota', map: 'Scorched Earth', address: 's9.ark.biggames.pl:27023', tags: ''},
    )
  }

  ngOnInit(): void {
  }

}

interface ServerInfo {
  name: string;
  map: string;
  address: string;
  tags: string;
}
