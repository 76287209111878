<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
  <div class="container">
    <!-- <a class="navbar-brand js-scroll-trigger" href="#page-top">BigGames.pl</a> -->
    <a class="navbar-brand js-scroll-trigger" routerLink="/" fragment="page-top">BigGames.pl</a>
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive"
      aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" routerLink="/" fragment="about">O nas</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" routerLink="/" fragment="services">Usługi</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" routerLink="/" fragment="portfolio">Projekty</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" routerLink="/" fragment="contact">Kontakt</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>
