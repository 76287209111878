<section id="ep404" class="errorpage text-center d-flex">
  <div class="container my-auto">
    <div class="row">
      <div class="jumbotron col-md-8 offset-md-2 mt-5 pl-5">
        <h1>404</h1>
        <hr>
        <h3>Strona nie istnieje</h3>
        <a class="btn btn-primary btn-xl js-scroll-trigger" routerLink="/"><i class="fa fa-arrow-left"
            aria-hidden="true"></i> Powrót</a>
      </div>
    </div>
  </div>
</section>
