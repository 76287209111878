<header class="masthead text-center text-white d-flex">
  <div class="container my-auto">
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <h1 class="text-uppercase">
          <strong>Sieć serwerów gier</strong>
        </h1>
        <hr>
      </div>
      <div class="col-lg-8 mx-auto">
        <p class="text-faded mb-5">Wpadniesz na chwilę, zostaniesz na dłużej!</p>
        <a class="btn btn-primary btn-xl js-scroll-trigger" href="#about">Przekonaj się</a>
      </div>
    </div>
  </div>
</header>

<section class="bg-primary" id="about">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <h2 class="section-heading text-white">Mamy to, czego potrzebujesz!</h2>
        <hr class="light my-4">
        <p class="text-faded mb-4">Nasza sieć to wiele serwerów gier rozlokowanych na wielu maszynach.
          Do komunikacji głosowej zaproponować możemy jedne z największych na świecie serwerów cenionej przez graczy
          aplikacji TeamSpeak&nbsp;3.
          Stabilność i bezpieczeństwo naszych usług stawiamy na bardzo wysokim poziomie co najlepiej oddaje ilość osób,
          które nam zaufało.
          Nie krępuj się!</p>
        <a class="btn btn-light btn-xl js-scroll-trigger" href="#services">Rozpocznij!</a>
      </div>
    </div>
  </div>
</section>

<section id="services">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 text-center">
        <h2 class="section-heading">W naszych usługach</h2>
        <hr class="my-4">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6 text-center">
        <div class="service-box mt-5 mx-auto">
          <i class="fas fa-4x fa-gem text-primary mb-3 sr-icon-1"></i>
          <h3 class="mb-3">Stabilne rozwiązania</h3>
          <p class="text-muted mb-0">Mocne maszyny z dedykowaną ochroną anty DDoS niedopuszczające do przerw nawet na
            moment w dostępie do usług.</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 text-center">
        <div class="service-box mt-5 mx-auto">
          <i class="fas fa-4x fa-paper-plane text-primary mb-3 sr-icon-2"></i>
          <h3 class="mb-3">Pod kontrolą</h3>
          <p class="text-muted mb-0">Zaprojektowane przez nas rozwiązania czuwają nad tym, aby serwery były na bierząco
            aktualizowane, a systemy gotowe do pracy o każdej porze.</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 text-center">
        <div class="service-box mt-5 mx-auto">
          <i class="fas fa-4x fa-code text-primary mb-3 sr-icon-3"></i>
          <h3 class="mb-3">Innowacje</h3>
          <p class="text-muted mb-0">Projektujemy własne modyfikacje do gier nie polegając jedynie na gotowych nudnych
            rozwiązaniach z sieci.</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 text-center">
        <div class="service-box mt-5 mx-auto">
          <i class="fas fa-4x fa-heart text-primary mb-3 sr-icon-4"></i>
          <h3 class="mb-3">Tworzenie z pasją</h3>
          <p class="text-muted mb-0">Też jesteśmy graczami, a praca przy rozwiniętaych projektach to już sama
            przyjemność!</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="p-0" id="portfolio">
  <div class="container-fluid p-0">
    <div class="row no-gutters popup-gallery">
      <div class="col-lg-4 col-sm-6">
        <a class="portfolio-box" href="#">
          <img class="img-fluid" src="../../assets/img/portfolio/thumbnails/hw_v1.jpg" alt="">
          <div class="portfolio-box-caption">
            <div class="portfolio-box-caption-content">
              <div class="project-category text-faded">
                Hurtworld legacy
              </div>
              <div class="project-name">
                hw69.pl
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-sm-6">
        <a class="portfolio-box" href="#">
          <img class="img-fluid" src="../../assets/img/portfolio/thumbnails/hw_v2.jpg" alt="">
          <div class="portfolio-box-caption">
            <div class="portfolio-box-caption-content">
              <div class="project-category text-faded">
                Hurtworld itemv2
              </div>
              <div class="project-name">
                hurt4u.pl
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-sm-6">
<!--        <a class="portfolio-box" [routerLink]="['/ark']">-->
        <a class="portfolio-box" href="#">
          <img class="img-fluid" src="../../assets/img/portfolio/thumbnails/ark.jpg" alt="">
          <div class="portfolio-box-caption">
            <div class="portfolio-box-caption-content">
              <div class="project-category text-faded">
                Ark
              </div>
              <div class="project-name">
                Cross ARK Behemot
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-sm-6">
        <a class="portfolio-box" href="#">
          <img class="img-fluid" src="../../assets/img/portfolio/thumbnails/ts3.jpg" alt="">
          <div class="portfolio-box-caption">
            <div class="portfolio-box-caption-content">
              <div class="project-category text-faded">
                Teamspeak 3
              </div>
              <div class="project-name">
                ts.biggames.pl
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-sm-6">
        <a class="portfolio-box" href="#">
          <img class="img-fluid" src="../../assets/img/portfolio/thumbnails/mt2.jpg" alt="">
          <div class="portfolio-box-caption">
            <div class="portfolio-box-caption-content">
              <div class="project-category text-faded">
                Metin2
              </div>
              <div class="project-name">
                Prywatny serwer Metin2
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-4 col-sm-6">
        <a class="portfolio-box" href="#">
          <img class="img-fluid" src="../../assets/img/portfolio/thumbnails/detroitbg.png" alt="">
          <div class="portfolio-box-caption">
            <div class="portfolio-box-caption-content">
              <div class="project-category text-faded">
                FiveM
              </div>
              <div class="project-name">
                Server GTA Role Play. <br>Discord: <i>https://discord.gg/KUygR9B84e</i>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>

<!-- <section class="bg-dark text-white">
  <div class="container text-center">
    <h2 class="mb-4">Free Download at Start Bootstrap!</h2>
    <a class="btn btn-light btn-xl sr-button" href="http://startbootstrap.com/template-overviews/creative/">Download
      Now!</a>
  </div>
</section> -->

<section id="contact">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 mx-auto text-center">
        <h2 class="section-heading">Bądźmy w kontakcie!</h2>
        <hr class="my-4">
        <p class="mb-5">Gotowy do rozpoczęcia kolejnego projektu z nami? To wspaniale! Odwiedź nas na Teamspeak3 lub
          wyślij e-mail, a my skontaktujemy się z Tobą tak szybko, jak to możliwe!!</p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 ml-auto text-center">
        <i class="fab fa-teamspeak fa-3x mb-3 sr-contact-1"></i>
        <p>ts.biggames.pl</p>
      </div>
      <div class="col-lg-4 mr-auto text-center">
        <i class="fas fa-envelope fa-3x mb-3 sr-contact-2"></i>
        <p>
          <a href="mailto:kontakt@biggames.pl">kontakt@biggames.pl</a>
        </p>
      </div>
    </div>
  </div>
</section>
