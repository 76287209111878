<div id="ark-page">
  <section id="server-list" class="text-center text-white d-flex">
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-8 mx-auto text-center py-5" style="background: #00000065;">
          <h1 class="section-heading text-white">ARK Behemot</h1>
          <h2 class="section-heading text-white">Lista serwerów</h2>
          <hr class="light my-4">
          <div *ngFor="let s of servers" class="mb-3">
            <h3 class="section-heading text-white">{{ s.name }} <small class="">[{{ s.map }}] <span
              *ngIf="s.tags">{{ s.tags }}</span></small></h3>
            <p>
              <span class="mr-3">IP: {{ s.address }}</span>
              <span>
                <a class="btn btn-primary btn-sm ml-2" [href]="'steam://connect/'+ s.address | safeUrl"
                  ><i class="fab fa-steam" aria-hidden="true"></i> Steam</a>
<!--                <a class="btn btn-primary btn-sm ml-2" [href]="'com.epicgames.launcher://apps/ARKSurvivalEvoled?action=lunch&silent=true&ip=' + s.address | safeUrl"><i class="fa fa-gamepad"></i> EPIC</a>-->
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
